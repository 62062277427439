import { Settings } from "../../app/modules/shared/interfaces/settings";

export const environment: Settings = {
  isProduction: false,
  dataProtectionUrl: 'https://enstroga.de/wp-content/uploads/ENSTROGA_Datenschutz_EB.pdf',
  legalNoticeUrl: 'https://enstroga.de/rechtliches/impressum/',
  homePageUrl: 'https://enstroga.de/',
  faqUrl: 'https://enstroga.de/haeufige-fragen/',
  membershipApplicationFormUrl: 'https://enstroga.de/wp-content/uploads/ENSTROGA_Vertragspartnerdaten.pdf',
  electricityLabelingUrl: 'https://enstroga.de/unternehmen/stromkennzeichnung/',
  copyrightBy: 'ENSTROGA AG',
  hotline: '030 40 91 91 91',
  brandName: 'ENSTROGA',
  brandEmail: 'energie@enstroga.de',
  customerPortalUrl: 'https://kundenportal.enstroga.de/',
  dashboardUrl: 'dashboard',
  moveUrl: 'move',
  moveSuccessUrl: 'success',
  loginUrl: 'login',
  magicKeyUrl: 'magic-key',
  magicKeyCreateUrl: 'create',
  meterReadingUrl: 'meter-reading',
  monthlyPaymentUrl: 'monthly-payment',
  contactFormUrl: 'contact',
  contractOverviewUrl: 'contract-overview',
  contactMessageSent: 'success',
  paymentInformationUrl: 'payment-information',
  documentsUrl: 'documents',
  orderCorrectionUrl : 'order-correction',
  minimumLoadingTimeInMilliseconds: 400,
  minimumProgressTimeInMilliseconds: 800,
  maximumFileLengthInBytes: 10485760,
  acceptedFileUploadExtensions: ['.png', '.jpg', '.pdf'],
  creditSettlementUrl: 'credit-settlement',
  showWaiveBalance: false,
  tariffUrl: 'tariff',
  mustSelectPayoutMethodForEachCreditSettlement: false,
  accountStatementUrl: 'account-statement',
  isContractOverviewCardVisible: true,
  isMoveCardVisible: true,
  isMeterReadingCardVisible: true,
  isMonthlyPaymentCardVisible: true,
  isPaymentInformationCardVisible: true,
  isDocumentsCardVisible: true,
  isCreditSettlementCardVisible: true,
  isAccountStatementCardVisible: true,
  isContactCardVisible: true,
  isExternalContactFormUrl: false,
  canCorrectOrder: true,
  showDayaheadPriceOverviewInLogin: false,
  dayaheadPriceOverviewCountry: '#',
  hasOtherPriceComponents: false,
};
